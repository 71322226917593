<template>
  <section v-if="siteData.length > 0">
    <!-- Site Select -->
    <b-card class="mb-4">
      <h4 align="center" class="mb-2">Sites</h4>
      <Flicking
        ref="flicking"
        class="mb-1"
        :options="flickingOptions"
        @ready="formatMenu('init', $event)"
        @will-change="formatMenu('before', $event)"
        @before-resize="formatMenu('resize', $event)"
      >
        <div
          class="mr-3 mb-1"
          v-for="i in flickingOptions.flickingRows"
          :key="i"
        >
          <b-button
            @click="goto(menuButtons.name)"
            v-for="menuButtons in rowItems(i)"
            :key="menuButtons.siteData"
            block
            variant="primary"
          >
            {{ menuButtons.name }}
          </b-button>
        </div>
      </Flicking>
      <b-pagination
        v-model="flickingOptions.currentPage"
        hide-goto-end-buttons
        per-page="1"
        align="center"
        first-number
        last-number
        v-show="siteData.length > 4"
        @input="panelControl('paginate', $event)"
        :total-rows="flickingOptions.flickingPagination"
      >
      </b-pagination>
    </b-card>

    <div v-for="(site, index) in siteData" class="mb-1" :key="index">
      <b-row>
        <b-col>
          <div
            style="position: absolute; top: -150px; left: 0"
            :id="site.name"
          ></div>
          <h3 class="mb-1">
            {{ site.name }}
          </h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-for="(camera, index) in site.cameras"
          :key="index"
          sm="12"
          md="3"
        >
          <b-card
            :img-src="require('@/assets/images/pages/camera.png')"
            img-alt="Card image cap"
            img-top
            class="border shadow-lg"
            no-body
          >
            <b-card-body class="text-center">
              <b-card-title>{{ camera.name }}</b-card-title>
              <b-card-text> {{ site.name }}</b-card-text>
              <b-button variant="danger" target="_blank" :href="camera.url">
                Live Stream
              </b-button>

              <b-row>
                <b-col align="right" class="mb-0">
                  <feather-icon
                    icon="HeartIcon"
                    size="14"
                    class="cursor-pointer"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
  <section v-else>
    <h1>Hello, and Welcome to Opal</h1>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BCardTitle,
  BEmbed,
  BCardText,
  BPagination,
  BImg
} from 'bootstrap-vue'
import { site } from '@/services/site.service.js'
import { Flicking } from '@egjs/vue-flicking'
import '@egjs/vue-flicking/dist/flicking.css'

export default {
  components: {
    Flicking,
    BRow,
    BCol,
    BEmbed,
    BButton,
    BCard,
    BCardBody,
    BPagination,
    BCardTitle,
    BCardText,
    BImg
  },
  data() {
    return {
      flickingOptions: {
        moveType: ['snap', { stopAtEdge: true }],
        bound: true,
        align: 'prev',
        buttonsPerRow: 1,
        flickingRows: 1,
        flickingPagination: 1,
        preventEventsBeforeInit: true,
        currentPage: 1
      },
      siteData: [
        {
          name: '',
          cameras: []
        }
      ]
    }
  },

  methods: {
    /*
     * Function to scroll item selected from Menu into view
     */

    goto(siteID) {
      // scroll to siteid with an offset of 100px
      document.getElementById(siteID).scrollIntoView({
        // scroll 200px above element
        behavior: 'smooth',
        block: 'start'
      })
    },
    panelControl(type, event) {
      if (type === 'paginate') {
        const moveToPage = event - 1
        this.$refs.flicking
          .moveTo(moveToPage)
          .then(
            // if next is successful
            () => {}
          )
          .catch(() => {})
      }
    },

    /*
     * Function to format the count of items to show in menu with rows
     */
    formatMenu(data, event) {
      const breakPoint = this.$store.getters['app/currentBreakPoint']
      const smRows = Math.ceil(this.siteData.length / 4)
      const totalRows = Math.ceil(this.siteData.length / 2)

      if (this.siteData.length < 4) {
        if (breakPoint == 'xs' || breakPoint == 'sm') {
          this.flickingOptions.buttonsPerRow = 4
          this.flickingOptions.flickingRows = smRows
        }
        if (breakPoint == 'md') {
          this.flickingOptions.buttonsPerRow = 2
          this.flickingOptions.flickingRows = totalRows
        }
        if (breakPoint == 'lg') {
          this.flickingOptions.buttonsPerRow = 2
          this.flickingOptions.flickingRows = totalRows
        }
        if (breakPoint == 'xl') {
          this.flickingOptions.buttonsPerRow = 2
          this.flickingOptions.flickingRows = totalRows
        }
      }
      if (event.eventType === 'willChange') {
        console.log(event.index)
        console.log(this.flickingOptions.flickingRows)
        this.flickingOptions.currentPage = event.index + 1
      }
      if (event.eventType === 'ready') {
      } else {
        if (breakPoint === 'xs' || breakPoint == 'sm') {
          this.flickingOptions.buttonsPerRow = 4
          this.$refs.flicking.panelsPerView = 1
          this.flickingOptions.flickingRows = smRows
          this.flickingOptions.flickingPagination = smRows
        }
        if (breakPoint === 'md') {
          this.flickingOptions.buttonsPerRow = 2
          this.$refs.flicking.panelsPerView = 2
          this.flickingOptions.flickingRows = totalRows
          this.flickingOptions.flickingPagination = totalRows - 1
        }
        if (breakPoint === 'lg') {
          this.flickingOptions.buttonsPerRow = 2
          this.$refs.flicking.panelsPerView = 3
          this.flickingOptions.flickingRows = totalRows
          this.flickingOptions.flickingPagination = totalRows - 2
        }
        if (breakPoint === 'xl') {
          this.flickingOptions.buttonsPerRow = 2
          this.$refs.flicking.panelsPerView = 3
          this.flickingOptions.flickingRows = totalRows
          this.flickingOptions.flickingPagination = totalRows - 2
        }
      }
    },
    rowItems(index) {
      return this.siteData.slice(
        (index - 1) * this.flickingOptions.buttonsPerRow,
        index * this.flickingOptions.buttonsPerRow
      )
    },
    /*
     * Function to get all sites from the API
     */
    async getAllSites() {
      try {
        site.getAllSites().then((data) => {
          this.siteData = []
          let sites = data.data.data
          sites.forEach((site) => {
            if (site.cameras.length > 0) {
              this.siteData.push(site)
            }
          })
        })
      } catch (error) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: error.response.data
            ? error.response.data.error
            : error.response.statusText
        })
      }
      return 'loaded'
    }
  },
  // created computed property to watch the window resize
  computed: {},
  created() {
    this.getAllSites()
  },
  mounted() {}
}
</script>
